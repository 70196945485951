<template>
    <div class="under">
        <div class="top">
            <img :src="avatar" alt="">
            <div class="top-t">
                <h1>{{ name }}</h1>
                <p>职位</p>
            </div>
        </div>
        <div v-if="finnshWeixin" class="erweima" @click="qrCode"><img src="../../assets/sm.png" alt=""></div>
        <div class="asset">
            <div class="eq" @click="goEquipment"><h1>资产查询</h1></div>
            <div class="parts" @click="goParts" ><h1>备件查询</h1></div>
        </div>
        <van-row class="work-bar">
            <div class="index-tittle">
                <img src="../../assets/menu.png" alt="">
                <div>工作台</div>
            </div>
            <van-grid :column-num="3">
                <van-grid-item to="/workorder/pm1">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-dianjian"></use>
                    </svg>
                    <p>自主点检</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm2">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-jiancha"></use>
                    </svg>
                    <p>自主维护</p>
                </van-grid-item>
                <van-grid-item to="/project/apply">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-baoxiu"></use>
                    </svg>
                    <p>新增报修</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm5">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-jiankanganquan"></use>
                    </svg>
                    <p>专业点检</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm3">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-weixiubaoxiu"></use>
                    </svg>
                    <p>专业维护</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm6">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-biaozhunhuacaipu"></use>
                    </svg>
                    <p>专业巡检</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm4">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-water"></use>
                    </svg>
                    <p>加润滑油</p>
                </van-grid-item>
                <van-grid-item to="/workorder/pm7">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-qinglilaji"></use>
                    </svg>
                    <p>节日维保</p>
                </van-grid-item>
                <van-grid-item to="/workorder/cm">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-weibao"></use>
                    </svg>
                    <p>故障维修</p>
                </van-grid-item>
            </van-grid>
        </van-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
import wxApi from '@/api/weixin/weixin'
import eqApi from '@/api/asset/equipment'
export default {
    name: "indexPage",
    inject: ['goUrl'],
    computed: {
        ...mapGetters(['name', 'avatar', 'userId']),
    },
    data() {
        return{
            finnshWeixin: false
        }
    },
    created() {
        this.getWxConfig()
    },
    methods: {
        getWxConfig() {
            const url = (window.location.href).split('#')[0]
            const _this = this
            wxApi.wxJSTicket({ url }).then(res =>{
                wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: res.data.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.signature,// 必填，签名
                    jsApiList: ['checkJsApi','scanQRCode'] // 必填，需要使用的 JS 接口列表
                })
                wx.ready(function(){
                    _this.finnshWeixin = true
                })
            })
        },
        qrCode() {
            const _this = this
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                    const str = res.resultStr
                    const eq = str.split('|')
                    if(eq[0] !== 'e') {
                        Toast('这不是设备');
                    } else {
                        eqApi.eqList({ coding: eq[1] }).then(res => {
                            console.log(res)
                            let id = res.data.data[0].id
                            console.log(id)
                            let url = `/asset/edit/${id}`
                            _this.$router.push(url)
                        })
                        // eq[1]
                    }
                }
            })
        },
        goEquipment() {
            this.goUrl('/asset')
        },
        goParts() {
            this.goUrl('/parts')
        }
    }
}
</script>

<style scoped>
.under{
    background-color: #f2f2f2;
    height: 100vh;
}
.top{
    width: 100%;
    height: 120px;
    background: url("../../assets/index-top-bgc.png") no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    padding: 20px;
}
.top img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.top-t{
    margin-left: 12px;
}
.top-t h1{
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
}
.top-t p{
    font-size: 12px;
    margin-top: 4px;
}
.erweima {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 32px;
}
.erweima img{
    width: 100%;
    height: auto;
}
.asset{
    width: 94%;
    height: 100px;
    background-color: #fff;
    margin: -20px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.asset h1{
    color: #fff;
    font-size: 18px;
    text-shadow: 0 0.10667rem 0.24rem #333;
    padding: 20px 10px;
}
.eq{
    width: 150px;
    height: 74px;
    background: url("../../assets/index-eq.png");
    background-size: 100% 100%;
}
.parts{
    width: 150px;
    height: 74px;
    background: url("../../assets/index-parts.png");
    background-size: 100% 100%;
}
.work-bar{
    width: 94%;
    background-color: #fff;
    margin: 34px auto;
    border-radius: 5px;
}
.index-tittle{
    display: flex;
    align-items:center;
    background-color: white;
    padding: 0.3rem 0.1rem 0.3rem;
}
.index-tittle img{
    margin-left: 18px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    display: inline-block;

}
.work-bar p{
    margin-top: 8px;
}
</style>
<style>
    .icon {
        width: 30px;
        height: 30px;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>
